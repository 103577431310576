import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { Button, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import ModalWrapper from "./ModalWrapper";
import Logo from "../assets/images/logo.webp";
import AviatorLogo from "../assets/images/icons/aviator-logo.webp";
import RegistrationIcon from "../assets/images/icons/registration-icon.webp";
import ArrowRight from "../assets/images/icons/arrow-right.webp";
import GiftIcon from "../assets/images/icons/gift-icon.webp";
import Bonuses from "../assets/images/icons/bonuses.webp";
import GamesSearchModal from "../views/components/GamesSearchModal";

const headerPages = [
  "home",
  "betby",
  "sports",
  "casinogames",
  "livecasino",
  "aviator",
  "evolution",
  "ezugi",
  "supernowa",
  "vivo",
  "xpg",
  "worldcasino",
  "qtech",
  "atlas-lobby",
  "/",
];

const Header = () => {
  const { appDetails } = useSelector((state) => state.app);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (currentScrollTop > lastScrollTop && currentScrollTop > 0) {
        // Scrolling down
        setIsSticky(true);
      } else if (currentScrollTop <= 0) {
        // Remove sticky class when scrolled all the way up
        setIsSticky(false);
      }
      setLastScrollTop(currentScrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  const [isproviderActive, setIsproviderActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const inlineStyle = isproviderActive ? { overflowX: "clip" } : {};
  const handleProvidersClick = () => {
    const isMobile = window.innerWidth < 992;

    if (isMobile) {
      setShowModal(true);
      document.body.classList.add("modal-providers");
      document.documentElement.style.overflow = "hidden";
    } else {
      setIsproviderActive(!isproviderActive);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    document.body.classList.remove("modal-providers");
    document.documentElement.style.overflow = "auto";
  };

  const menuItems = [
    { name: "Home", path: "/", activeKey: "/" },
    { name: "Sports", path: "/betby", activeKey: "betby" },
    { name: "Exchange", path: "/sports/Inplay", activeKey: "sports" },
    { name: "Casino", path: "/casinogames", activeKey: "casinogames" },
    { name: "Live-games", path: "/livecasino", activeKey: "livecasino" },
    {
      name: "Aviator",
      path: "casino/spribe/aviator",
      activeKey: "aviator",
      isLogo: true,
      logo: AviatorLogo,
    },
  ];
  const providers = [
    { name: "Evolution", path: "/casino/evolution", activeKey: "evolution" },
    { name: "Ezugi", path: "/casino/ezugi", activeKey: "ezugi" },
    { name: "Supernowa", path: "/casino/supernowa", activeKey: "supernowa" },
    { name: "Vivo", path: "/casino/vivo", activeKey: "vivo" },
    { name: "XPG", path: "/casino/xpg", activeKey: "xpg" },
    {
      name: "Worldcasino",
      path: "/casino/worldcasino",
      activeKey: "worldcasino",
    },
    { name: "Qtech", path: "/casino/qtech", activeKey: "qtech" },
  ];

  return (
    <header className="header beforeheader">
      <div className="top_head d-none d-lg-block">
        <div className="inner-box">
          <div className="left-section">
            <a href="#" className="vip-level">
              <span>Welcome Bonus</span>
              <span className="vip-icon"></span>
              <img src={ArrowRight} alt="right arrow" />
            </a>
          </div>

          <div className="right-section">
            <div className="vip-gift">
              <a href="#" className="promotion">
                <div className="img-box">
                  <img src={GiftIcon} alt="promotions" />
                </div>
                <span>Promotions and bonuses</span>
                <img src={Bonuses} alt="bonuses" className="bonus" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`bottom_head ${isSticky ? "sticky" : ""}`}>
        <div className="inner-box">
          <div className="logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" height={60} />
              {/* <img src={Logo} alt="Logo" /> */}
            </a>
          </div>

          <div className="head-links">
            <ul style={inlineStyle}>
              {menuItems.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.path}
                    className={`
                      ${
                        location.pathname === item.path ||
                        activePage === item.activeKey
                          ? "active"
                          : ""
                      }
                      ${item.name === "Aviator" ? "game-logo" : ""}
                    `}
                  >
                    <span>
                      {item.isLogo ? (
                        <img src={item.logo} alt={item.name} />
                      ) : (
                        item.name
                      )}
                    </span>
                  </a>
                </li>
              ))}
              <li className="providers">
                <a href="javascript:void(0)" onClick={handleProvidersClick}>
                  <span>Games by provider</span>
                </a>
                <ul
                  className={`providers-games d-none d-md-flex ${
                    isproviderActive ? "active" : ""
                  }`}
                >
                  {providers.map((provider) => (
                    <li key={provider.name}>
                      <a
                        href={provider.path}
                        className={
                          activePage === provider.activeKey ? "active" : ""
                        }
                      >
                        <span>{provider.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="providers">
                <a
                  href="/atlas-lobby"
                  className={activePage === "atlas-lobby" ? "active" : ""}
                >
                  <span>Sportsbook 2</span>
                </a>
              </li>
            </ul>

            <div className="d-lg-none">
              <GamesSearchModal />
            </div>
          </div>

          <div className="rightSec ms-lg-auto">
            <Button
              variant=""
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
              }}
              className="btn_signin"
            >
              Login
            </Button>
            <Button
              variant=""
              onClick={() => {
                handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
              }}
              className="btn_signup"
            >
              <span className="d-none d-lg-inline-flex">
                <img src={RegistrationIcon} alt="registration" />
              </span>
              Registration
            </Button>
          </div>
        </div>
      </div>

      <Modal
        className="providers-list"
        show={showModal}
        onHide={handleModalClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <div className="providers-link">
            <ul>
              {providers.map((provider) => (
                <li key={provider.name}>
                  <a
                    href={provider.path}
                    className={
                      activePage === provider.activeKey ? "active" : ""
                    }
                  >
                    <span>{provider.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModals}
        />
      )}
    </header>
  );
};

export default Header;
